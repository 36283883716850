import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './components/Menu';
import Home from './pages/Home';
import About from './pages/About';
import Footer from './components/Footer'
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
import Kontakt from './pages/Kontakt'
import Beitraege from './pages/Beitraege';
import Videos from './pages/Videos';
import Links from './pages/Links';
import Termine from './pages/Termine';

const App: React.FC = () => {
  return (
    <Router>
      <div id="page-container">
        <Menu />
        <div id="content-wrap" className="container mt-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/beitraege" element={<Beitraege />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/links" element={<Links />} />
            <Route path="/termine" element={<Termine />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
