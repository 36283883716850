import React from 'react';

const Termine: React.FC = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Termine</h1>
      <div className="card mb-3">
        <div className="card-header text-center">
          <h2>Das Gesetz des Lebens</h2>
          <h3>Vier aufeinanderfolgende Vorträge über den Umgang mit Herausforderungen im Leben wie:</h3>
          <p>Angst, Stress, Enttäuschung, Trauer, Verlust, Krankheit, Konflikte, Krisen</p>
        </div>
        <div className="card-body">
          <p>
            Dr. Horst Müller konnte in seiner langjährigen Praxis beobachten, wie die Krankheit entsteht und welche Prozesse sie auslösen.
            Ohne diese Kenntnis führt eine Behandlung der Symptome nicht zum gewünschten Erfolg der Genesung. Die Ursachenbehebung
            und die Behandlung der Krankheit müssen miteinander ablaufen, damit dauerhafte Gesundheit gesichert ist.
            Seine Vorträge sind eine Hilfe bei dem Verständnis des menschlichen Wesens.
          </p>
          <ul>
            <li>
              <strong>Freitag, 30.8. 19.00 Uhr</strong>
              <br />
              Wenn mein Körper spricht
            </li>
            <li>
              <strong>Samstag, 31.8.</strong>
              <br />
              11.00 Uhr - Was bestimmt mich unbewusst
              <br />
              14.30 Uhr - Ausweg aus der Krankheit
              <br />
              17.00 Uhr - Der Weg zu einem befreiten und gesunden Leben
            </li>
            <li>
              <strong>Sonntag, 1.9.</strong>
              <br />
              Persönliche Beratungsgespräche nach Terminabsprache unter 0170 3800489 / Karl Wunder
            </li>
          </ul>
          <div className="location mt-5">
            <h3>Veranstaltungsort</h3>
            <p>
              Treffpunkt Leben und Gesundheit
              <br />
              Fuhrenkamp 26/Ecke Hackethalstraße
              <br />
              30851 Langenhagen
            </p>
          </div>
        </div>
        <div className="card-footer text-center">
          <p>Veranstalter: Aktive Christen e. V.</p>
          <p>Telefon: 05 11/98 42 20 86</p>
          <p><strong>Eintritt frei</strong></p>
        </div>
      </div>
    </div>
  );
}

export default Termine;
