// src/pages/Beitraege.tsx
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Post from '../components/Post';
import { PostType } from '../types/Post';

const Beitraege: React.FC = () => {
  const [posts, setPosts] = useState<PostType[]>([]);

  useEffect(() => {
    fetch('/data/quotations.json')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setPosts(data);
        } else {
          console.error('Fetched data is not an array', data);
        }
      })
      .catch(error => console.error('Error loading the JSON data:', error));
  }, []);

  return (
    <div className="container mb-3">
      <h1 className="my-4">Beitragsübersicht</h1>

      <div className="list-group">
        {posts.length > 0 ? (
          posts.map((post, index) => (
            <Post key={index} {...post} displayType="list" />
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Beitraege;
