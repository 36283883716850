import React from 'react';
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container text-center">
        <div className="row">
          <div className="col">
            <Link to="/impressum">Impressum</Link>
          </div>
          <div className="col">
            <Link to="/datenschutz">Datenschutz</Link>
          </div>
          <div className="col">
            <Link to="/kontakt">Kontakt</Link>
          </div>
          <div className="col">
            <Link to="/about">Über uns</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
