import React from 'react';

const Links: React.FC = () => {
  return (
    <div className="container mt-3">
      <h1>Links</h1>
      <p>Links</p>
    </div>
  );
}

export default Links;
