import React from 'react';
import VideoThumbnail from '../components/VideoThumbnail';

const Videos: React.FC = () => {
  return (
    <div className="container mt-3">
      <div className="row">
        <h2>Videos</h2>
        <div className='video-section'>
          <VideoThumbnail videoId="1L0Xt1yyt04" title="Was ist die Wahrheit" />
          <VideoThumbnail videoId='O3rv5R6r7wg' title='Die Wahrheit in der Natur' />
          <VideoThumbnail videoId='rXfb8Vo573o' title='Die Wahrheit über uns' />
          <VideoThumbnail videoId='vG9KmzHWIpg' title='Die Lüge, die uns begleitet' />
          <VideoThumbnail videoId='nY2g5M_hRBU' title='Die Lüge durch Wahrheit ersetzen' />
        </div>
      </div>
    </div>
  );
}

export default Videos;
