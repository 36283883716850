import React from 'react';

const Impressum: React.FC = () => {
  return (
    <div className="container mt-3">
      <h1>Impressum</h1>

      <p>Karl Wunder<br />
        Blumenweg 7<br />
        30855 Langenhagen<br />
        </p>

      <h2>Kontakt</h2>
      <p>Telefon: 0511 7639298<br />
        E-Mail: karlwu@t-online.de</p>

      <h2>Redaktionell verantwortlich</h2>
      <p>Karl Wunder<br />
        Blumenweg 7<br />
        30855 Langenhagen</p>
    </div>
  );
}

export default Impressum;
