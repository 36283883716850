import React, { useState } from 'react';
import ConfirmModal from './ConfirmModal';
import { auto } from '@popperjs/core';

interface VideoThumbnailProps {
  videoId: string;
  title: string;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ videoId, title }) => {
  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

  const handleThumbnailClick = () => {
    setVideoUrl(`https://www.youtube.com/watch?v=${videoId}`);
    setShowModal(true);
  };

  const handleConfirm = () => {
    setShowModal(false);
    window.open(videoUrl, '_blank');
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-3">
      <div className="video-thumbnail mx-2" /*style={{lineHeight: 0, fontSize: auto}}*/>
        <img src={thumbnailUrl} alt={title} style={{ width: '100%', borderRadius: '10px' }} onClick={handleThumbnailClick} />
        <ConfirmModal
          show={showModal}
          onHide={handleClose}
          onConfirm={handleConfirm}
          title="Video öffnen"
          message="Möchtest du das Video wirklich öffnen?"
        />
      </div>
    </div>
  );
};

export default VideoThumbnail;
