import React from "react";
import Image from "../assets/sunrise1.webp";
import Videos from "./Videos";

const Home: React.FC = () => {
  return (
    <div>
      <div className="image-container">
        <h1>
          Weltlich gesinnt
          <br />
          oder
          <br />
          schon heilig?
        </h1>
        <img
          src={Image}
          alt="Spiritual renewal landscape"
          style={{ width: "150px", height: "auto", margin: "auto auto", borderRadius: '20px' }}
        />
      </div>
      <p>
        Römer 12,2
        <br />
        <strong>Und stellt euch nicht dieser Welt gleich</strong>, sondern ändert euch durch die
        <strong> Erneuerung eures Sinnes</strong>, auf dass ihr prüfen könnt, was Gottes Wille
        ist, nämlich das Gute und Wohlgefällige und Vollkommene.
      </p>
      <p>
        Thessalonicher 4,3
        <br />
        Denn das ist der Wille Gottes, eure <strong>Heiligung</strong>
      </p>
      <p>
        Eine Einleitung mit Anspruch!
        <br />
        Aber ich lebe doch in der Welt, wie soll ich mich ihr nicht
        gleichstellen?
        Wenn Gottes Wille das Gute, Wohlgefällige und Vollkommene ist, warum ist
        es dann hier nicht so?
        Wodurch werde ich weltlich? Durch die Geburt und nachfolgend durch meine
        Entwicklung. Alle Babys sind zunächst absolut kreativ, und zu lernen ist
        ihre größte Freude.
        Dann folgen Kindergarten und Schule. Jeder lernt das Gleiche. Talente
        der Einzelnen werden nicht gefördert. Stillsitzen und Zuhören werden
        trainiert. Eine Bewertung erfolgt durch Noten, das Wettbewerbsdenken
        wird in Gang gesetzt. Die Kinder werden den Interessen der Welt
        angepasst, Leistung und materielle Werte zählen. Kritisches Denken oder
        Verhalten werden aberzogen – bitte nichts hinterfragen, keine anderen
        Lösungswege, keine andere Meinung.
        Viele Jugendliche betäuben sich mit extremer Musik, Drogen und Anderem,
        um diesem vorgegebenen Druck zu entgehen und ihre eigene Welt zu
        erschaffen.
        Mit 18 geht es zur Wahl, dort gibt man seine Stimme ab! Die
        Verantwortung wird einem abgenommen.
        Es gibt keine Ausbildung in der Natur mit Tieren, Pflanzen,
        Gartenarbeit, über Ehe, Familie, Umgang mit Finanzen oder gar über eine
        gesunde Lebensweise in seinem ganzen Umfang.
        Wir werden manipuliert durch Angst und Sorge: Du brauchst
        Versicherungen, Altersvorsorge, Krankheitsvorsorge durch Impfungen –
        Krankheit ist ein riesiges Geschäft.
        Hinzu kommen noch Religionen mit einem strafenden Gott und der ewig
        brennenden Hölle. Das gibt den Regierenden, die durch den Fürsten der
        Welt geleitet werden, eine enorme Macht.
        Die Medien in all ihrer Vielfalt sorgen für den Rest an Ablenkung.
        Kaufe, vergnüge dich, sehe fern, das neueste Handy, Fußball und Bier,
        Urlaub – Brot und Spiele. Unterhaltungsprogramm (unten halten!) am
        laufenden Band, und die Menschen sind glücklich.
        Du auch?
      </p>
      <p className="p-center"><strong>Hier die Lösung:</strong></p>
      <p className="p-center"><strong>Freiheit durch Wiedergeburt und Änderung der Gesinnung.</strong></p>
      <p>
        Vor 2000 Jahren kam Jesus Christus auf diese Welt, um die Menschen zu heilen und Ihnen die befreiende Botschaft zu bringen.
        Ich bin der Weg, die Wahrheit und das Leben. Joh. 14.6
        Und die Wahrheit wird euch freimachen. Joh. 8.32
        Seine Aufgabe war, die Menschen völlig wiederherzustellen. Ihnen Gesundheit, Frieden und charakterliche Vollkommenheit zu schenken.
        Viele gehen durchs Leben als ob sie kein großes Ziel hätten, für das man leben könnte, als ob es keinen hohen Standard gäbe, den man erreichen soll.
        Ein Grund hierfür liegt in der geringen Selbsteinschätzung. Christus bezahlte einen unendlich hohen Preis für uns und wünscht deshalb, daß wir uns gemäß
        dieses Preises unseres Wertes bewusst sind.
        Mangelndes Selbstwertgefühl und Schuldgefühle können Ursachen von Krankheiten sein.
        Denn also hat Gott die Welt geliebt, daß er seinen Eingeborenen Sohn gab, auf das alle, die an ihn glauben nicht verloren werden, sondern das ewige
        Leben haben. Joh. 3.16
      </p>
      <p className="p-center"><strong>Wenn wir geliebte Geschöpfe sind und er uns das ewige Leben schenken will, wie sieht uns dann Gott?</strong></p>
      <p className="p-center"><strong>Hier die Antwort aus der Bibel.</strong></p>
      <p>
        Jesaja. 43.4 <br />
        ............weil du in meinen Augen so wertgeachtet und auch herrlich bist und weil ich dich lieb habe.
        <br />
        Zefanja 3.17 <br />
        Denn der Herr, dein Gott, ist bei dir, ein starker Heiland. Er wird sich über dich freuen und freundlich sein. Er wird dir vergeben in seiner Liebe und wird
        über dich mit Jauchzen fröhlich sein.
      </p>
      <p className="p-center"><strong>Kann ich diese Zuneigung annehmen und folgendes für mich formulieren?</strong></p>
      <p>
        Ich (mein Name) bin ein geliebtes Geschöpf Gottes. Einzigartig. Durch Jesus Christus erlöst und Miterbe eines himmlischen Königreichs mit
        ewigem Leben.
      </p>
      <p className="p-center"><strong>Glücklichsein ist eine Entscheidung!</strong></p>
      <p>
        Gott hält für jeden eine Freude bereit, die von Reichen und Armen gleichermaßen empfunden werden kann - die Freude der Entwicklung einer sauberen Gedankenwelt.
        Ursachen von Krankheiten können unter anderem auch Stress, Angst, Sorge und mangelndes Selbstwertgefühl sein, die durch falsches Denken ausgelöst werden.
      </p>
      <p>
        Ellen White schreibt dazu in ihrem Buch "Der Weg zur Gesundheit" auf Seite 196:
        Kummer, Angst, Unzufriedenheit, Reue, Selbstanklage, Schuld, Misstrauen - alles trägt dazu bei, die Lebenskräfte zu schwächen und Verfall und Tod herbeizuführen.
        Mut, Hoffnung, Glaube, Mitgefühl und Liebe förden die Gesundheit und verlängern das Leben. Ein zufriedenes Gemüt und ein fröhlicher Geist bedeuten Gesundheit für den Körper und Kraft für die Seele.
      </p>
      <p>Sprüche 17.22</p>
      <p>"Ein fröhliches Herz tut dem Leibe wohl"</p>
      <p>
        Jesus Christus möchte Ihnen Gesundheit, Frieden und ewiges Leben in einer neuen heilen Welt schenken. Sie brauchen nur sein Erlösungswerk anzunehmen. Er wird Sie von Ihren Schuldgefühlen befreien und Sie werden
        durch den Geist Gottes in der Lage sein, Ihre <strong>Gesinnung</strong> zu ändern.
        Machen Sie sich bewusst, dass Sie ein einmaliges, wertvolles und geliebtes Geschöpf sind. Befreien Sie sich von Angst und Sorge und gestalten Sie Ihren Tagesablauf in Dankbarkeit.
      </p>
      <p>Zum Start in die <strong>neue Gesinnung</strong> möchte ich Ihnen folgenden Bibelvers empfehlen:</p>
      <p>Philipper 4.4</p>
      <strong>
        <p>
          Freuet euch in dem Herrn alle Wege und abermals sage ich: Freuet euch! Eure Güte lasst kund sein allen Menschen! Der Herr ist nahe! Sorgt euch um nichts, sondern in allen Dingen lasst eure Bitten im Gebet und Flehen mit Danksagung vor Gott kundwerden! Und der Friede Gottes, der höher ist als alle Vernunft, wird eure Herzen und Sinne bewahren in Christus Jesus.
        </p>
      </strong>
      <p>Sie können sich gerne bei mir melden und zu einem kostenlosen Gesundheitsseminar vor Ort oder auch online anmelden.</p>
      <p>Ich wünsche Ihnen Gottes Segen.</p>
      <p>
        Karl Wunder
        <br />
        Tel. 01703800489
      </p>
      <div className="video-section container-fluid">

        <Videos />
      </div>
    </div>
  );
};

export default Home;
