import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Card, Button, Modal } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { PostType } from '../types/Post';

type PostProps = PostType & {
  displayType: 'card' | 'list' | 'media' | 'accordion' | 'modal';
  eventKey?: string; // Only used for accordion
};

const Post: React.FC<PostProps> = ({ title, text, author, page_number, book_title, displayType, eventKey }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sanitizedText = DOMPurify.sanitize(text);

  const renderPostContent = () => (
    <>
      <h5>{title}</h5>
      <p>{parse(sanitizedText)}</p>
      <footer className="blockquote-footer">
        {author} - <cite title={book_title}>{book_title}</cite>, Seite {page_number}
      </footer>
    </>
  );

  switch (displayType) {
    case 'card':
      return (
        <div className="card mb-3">
          <div className="card-body">
            {renderPostContent()}
          </div>
        </div>
      );

    case 'list':
      return (
        <div className="list-group-item">
          {renderPostContent()}
        </div>
      );

    case 'media':
      return (
        <div className="media mb-3">
          <img src="https://via.placeholder.com/64" className="mr-3" alt="Thumbnail" />
          <div className="media-body">
            {renderPostContent()}
          </div>
        </div>
      );

    case 'modal':
      return (
        <>
          <div className="list-group-item" onClick={handleShow}>
            <h5>{title}</h5>
            <p><small className="text-muted">by {author} - Page: {page_number}</small></p>
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {renderPostContent()}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );

    default:
      return null;
  }
};

export default Post;
