import React from 'react';
import KarlImg from '../assets/Karl.png';

const About: React.FC = () => {
  return (
    <div>
      <h1>Über uns</h1>
      <p>
        Mein Name ist Karl Wunder. Ich bin 1951 geboren und seit 1996 mit meiner Frau Marion verheiratet. Wir haben 2 erwachsene Töchter und 1 erwachsenen Sohn.
        1991 habe ich nach einer Lebenskrise und einer Krebsdiagnose zu Gott gefunden.
        Ich habe mich taufen lassen, mein Leben Jesus übergeben und mich der Freikirche der Siebententags Adventisten angeschlossen. <br />
        Seitdem habe ich viele Erfahrungen mit Gott gemacht und viele neue Erkenntnisse gewonnen.
        Besonders fasziniert haben mich die biblischen Weisheiten über Gesundheit. Das hat mein Leben völlig verändert. <br />
        Ich bin Gott sehr dankbar, daß er mir eine Ehefrau zur Seite gestellt hat, die eine Predigerausbildung hat und wir gemeinsam viel Freude an dem Studium in Gottes Wort haben. <br />
        Vielleicht kann ich Sie ermutigen, dieses neue Leben mit Jesus auch einmal auszuprobieren.
        Sie können jederzeit mit mir persönlich in Kontakt treten und wir können gerne gemeinsam über das Thema "In neuer Gesinnung" sprechen. <br /><br />Ich wünsche Ihnen Gottes Segen <br />
        Ihr Karl Wunder
        <br />Tel. 01703800489
      </p>
      <img src={KarlImg} alt="Profilbild" style={{ width: '128px', height: 'auto', borderRadius: '10px' }} />
    </div>
  );
}

export default About;
