import React from 'react';

const Kontakt: React.FC = () => {
  return (
    <div className="container mt-3">
      <h1>Kontakt</h1>
      <p>
        <strong>Name:</strong> <br />
        Karl Wunder <br />
      </p>
      <p>
        <strong>Adresse:</strong> <br />
        Blumenweg 7 <br />
        30855 Langenhagen
      </p>
      <p>
        <strong>Telefon:</strong> <br />
        0511 7639298
      </p>
      <p>
        <strong>Email:</strong> <br />
        karlwu@t-online.de
      </p>
    </div>
  );
}

export default Kontakt;
